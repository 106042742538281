/* colors */

.bg-custom-light {
  background-color: whitesmoke;
}

.btn-custom-primary {
  background-color: #27a0a8;
  color: whitesmoke;
  border: none;
}

.text-custom-primary {
  color: #10253e;
}

.bold {
  font-weight: bold;
}

/* navbar */

ul li {
  list-style: none;
  margin: 5px;
}

ul li:hover {
  background-color: lightgrey;
  border-radius: 10px;
}

#services {
  position: absolute;
  background-color: whitesmoke;
  padding: 0.5rem 0;
  margin: 10px 0;
  border-radius: 10px;
  z-index: 100;
  display: none;
}

#menu-item:hover #services {
  display: block;
}

.brand-logo {
  object-fit: contain;
  width: 5rem;
}

.parent {
  position: relative;
}

.middle {
  height: 600px;
  background-color: whitesmoke;
  clip-path: circle(40% at 3% 9%);
  position: absolute;
  width: 100vw;
  z-index: -1;
}

.back {
  height: 600px;
  background-color: #27a0a850;
  clip-path: circle(80% at 10% -9%);
  position: absolute;
  width: 100vw;
  z-index: -2;
}

.contact-us {
  background-color: #27a0a870;
  height: 30vh;
  width: 100vw;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  clip-path: polygon(0 100%, 100% 0%, 100% 100%, 0% 100%);
}

.front {
  width: 100vw;
}

.header {
  font-weight: 500;
  font-size: 3rem;
  line-height: 4.5rem;
  color: #10253e;
}

.sub-header {
  font-size: 1.4rem;
  font-weight: 400;
}

/* showcase */
.sc-child {
  filter: grayscale(1);
}

.sc-child:hover {
  filter: grayscale(0);
}

/* Reviews */
.reviews {
  height: 80vh;
}

.review-count {
  font-weight: 400;
  font-size: 1.3rem;
}

/* imageslider */
.slider {
  width: 100vw;
  height: 100vh;
  transition: all 2s ease-in-out;
}

.slider img {
  width: 100vw;
  height: 100vh;
}

.content {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: justify;
}

.img {
  width: 100%;
  overflow: hidden;
}

.credits {
  font-weight: 600;
  font-size: large;
}

.filters {
  background: linear-gradient(to top, black, #00000099, #00000050, #00000005);
  z-index: 2;
  height: 100vh;
  width: 100vw;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none !important;
}

/* Features */
.features {
  background-color: #fdedeb;
  padding: 4rem 0rem 2rem 4rem;
  margin: 5rem 0;
}

.title {
  text-align: center;
  color: #10253e;
  font-weight: bold;
  font-size: 3rem;
}

.level-hero {
  margin-right: 0px;
  right: -2rem;
}

/* collaborate */
.collaborate {
  background-color: #b7cefe;
  min-height: 100vh;
}

.effect-label {
  position: absolute;
  color: whitesmoke;
  top: 2rem;
  font-size: large;
  font-weight: 600;
}

/* Before After Second Screen */
.img-back,
.img-front {
  position: absolute;
}

.img-front {
  right: 10rem;
  top: 10rem;
  object-fit: contain;
  width: 90%;
}
.img-back {
  right: 0;
  top: 0;
  object-fit: contain;
  width: 90%;
}

.featured-item {
  max-height: 70vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.feature-desc {
  position: absolute;
  z-index: -1;
}

/* Landing Page */

.menu {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  background-color: #27a0a8;
}

.menu-hover {
  padding: 5px;
  border-radius: 6px;
}
.menu-hover:hover {
  background-color: #0c7179;
}

/* Contact Us */
.social-icons-raw {
  filter: grayscale(0%);
}

.social-icons {
  filter: grayscale(100%);
}
.social-icons:hover {
  filter: grayscale(0%);
}

/* Footer */
.footer {
  background-color: #10253e;
  color: whitesmoke;
}
.footer p,
.footer a,
address {
  font-weight: 400;
}

.footer-links {
  color: white;
  text-decoration: none;
}

.footer-links:hover {
  color: #0c7179;
}

/* Features & Services */

.fns,
.portfolio {
  min-height: 100vh;
  width: 100vw;
  background-color: #27a0a850;
}

.feedback {
  min-height: 50vh;
  width: 100vw;
  background-color: #27a0a850;
}
.about {
  height: 70vh;
  width: 100vw;
  background-color: #27a0a850;
}

.feedback .carousel-indicators {
  display: none;
}

.feedback-section {
  font-size: large;
  font-weight: 400;
}

/* About Us */
.about {
  background-color: #27a0a820;
  position: relative;
  overflow: hidden;
}
.about > div {
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: 100;
}

.footer-logo {
  object-fit: contain;
  width: 80vw;
}

.content {
  height: auto;
  width: 100vw;
}

.landing-logo {
  width: 30vw;
}

.landing-image {
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .landing-image {
    object-fit: cover;
    height: 100vh;
  }
}

@media screen and (min-width: 480px) {
  .landing-logo {
    width: 120px;
  }
  .content {
    height: 90vh;
    width: 75vw;
  }
  .footer-logo {
    object-fit: contain;
    width: 100vw;
  }

  .middle {
    height: 600px;
    background-color: whitesmoke;
    clip-path: circle(20% at 3% 9%);
    position: absolute;
    width: 100vw;
    z-index: -1;
  }

  .back {
    height: 600px;
    background-color: #27a0a850;
    clip-path: circle(40% at 10% -9%);
    position: absolute;
    width: 100vw;
    z-index: -2;
  }
}
